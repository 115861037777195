





















































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import VInputMixin from "./VInputMixin";
import ClickOutside from "vue-click-outside";
import VDatePickerCalendar from "./components/VDatePickerCalendar.vue";
import dayjs from "dayjs";

@Component({
  components: { VDatePickerCalendar },
  inheritAttrs: false,
  directives: {
    ClickOutside,
  },
})
export default class VDatePicker extends Mixins(VInputMixin) {
  showCalendar = false;
  inputFocused = false;
  inputValue = "";

  @Prop({ default: true }) showIcon?: boolean;
  @Prop({ default: "" }) inputClass?: string;
  @Prop({ default: "" }) outputFormat?: string;

  onSelectedDateChanged(val: Date | undefined) {
    if (val instanceof Date && !isNaN(val.getTime())) {
      if (this.outputFormat) {
        this.$emit("change", dayjs(val).format(this.outputFormat));
      } else this.$emit("change", val.toUTCString());
    }
  }

  @Watch("inputFocused")
  onInputFocusedChanged(val: boolean) {
    if (val && this.$options.filters && this.$options.filters.date) {
      this.inputValue = this.$options.filters.date(this.value);
    }
    this.$emit("inputFocused", val);
  }

  inputClicked() {
    if (this.$attrs.disabled) return;
    this.showCalendar = !this.showCalendar;
    this.inputFocused = false;
  }

  get listeners() {
    return Object.assign({}, this.$listeners, {
      // This ensures that the component works with v-model
      change: (event: any) => {
        if (this.outputFormat) {
          this.$emit(
            "change",
            dayjs(event.target.value, "DD/MM/YYYY", true).format(
              this.outputFormat
            )
          );
          return;
        }
        this.$emit("change", event.target.value);
      },
    });
  }

  get formattedValue() {
    return this.value ? this.$options?.filters?.date(this.value) : "-";
  }

  get computedInputValue() {
    return this.inputFocused ? this.inputValue : this.value;
  }

  set computedInputValue(newValue: string) {
    const sanitizedValue = newValue.replace(/[^0-9]/g, "");
    if (newValue.length < this.inputValue.length) {
      this.inputValue = sanitizedValue;
    } else {
      if (this.inputValue.length >= 4) {
        this.inputValue =
          sanitizedValue.slice(0, 2) +
          "/" +
          sanitizedValue.slice(2, 4) +
          "/" +
          sanitizedValue.slice(4);
      } else if (this.inputValue.length >= 2) {
        this.inputValue =
          sanitizedValue.slice(0, 2) + "/" + sanitizedValue.slice(2);
      } else this.inputValue = sanitizedValue;
    }
  }

  hideCalendar() {
    this.inputFocused = false;
    this.showCalendar = false;
  }
}
