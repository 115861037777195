import { API_URI } from "@/lib/http/api";
import { AxiosResponse } from "axios";
export function formatUri(uri: TemplateStringsArray, ...values: any[]) {
  let result = "";
  for (let i = 0; i < values.length; ++i) {
    result += uri[i];
    result += encodeURIComponent(String(values[i]));
  }
  result += uri[uri.length - 1];
  return API_URI + result;
}

export async function poll(
  fn: any,
  params: any,
  validate: Function,
  interval: number,
  maxAttempts: number
): Promise<any> {
  let attempts = 0;
  const executePoll = async (resolve: any, reject: any) => {
    try {
      const result = await fn(params);
      attempts++;
      if (validate(result)) {
        return resolve(result);
      } else if (maxAttempts && attempts === maxAttempts) {
        return reject(new Error("Something went wrong, try again"));
      } else {
        setTimeout(executePoll, interval, resolve, reject);
      }
    } catch (error: any) {
      return reject(new Error("Something went wrong, try again"));
    }
  };

  return new Promise(executePoll);
}
