









































































import Vue from "vue";
import VDatePickerCalendarGrid from "./components/VDatePickerCalendarGrid.vue";

interface MonthData {
  firstDay: Date | null;
  lastDay: Date | null;
  offset: number;
  days: number[];
  monthNumber: number;
  year: number;
}

export default Vue.extend({
  components: { VDatePickerCalendarGrid },
  name: "VDatePickerCalendar",
  props: {
    isStartDateAndEndDateCalendar: {
      required: false,
      type: Boolean,
      default: false,
    },
    showCalendar: { required: true, type: Boolean },
    date: { required: false, type: String },
    multiDate: { required: false, type: Boolean, default: false },
    startDate: { required: false, type: Date },
    endDate: { required: false, type: Date },
    selectDateProp: { required: false, type: String, default: "" },
  },
  data() {
    return {
      monthsString: [
        "january",
        "february",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "november",
        "december",
      ],
      viewingStartYear: new Date(this.startDate || this.date).getFullYear(),
      viewingStartMonth: new Date(this.startDate || this.date).getMonth(),
      viewingEndYear:
        new Date(this.startDate || this.date).getMonth() === 11
          ? new Date(this.startDate || this.date).getFullYear() + 1
          : new Date(this.startDate || this.date).getFullYear(),
      viewingEndMonth:
        (new Date(this.startDate || this.date).getMonth() + 1) % 12,
      selectedDate: null as Date | null,
    };
  },
  computed: {
    sameMonthAndSameYear(): boolean {
      if (!this.isValidDate(this.startDate) || !this.isValidDate(this.endDate))
        return false;

      return (
        this.startDate.getMonth() === this.endDate.getMonth() &&
        this.startDate.getFullYear() === this.endDate.getFullYear()
      );
    },
    monthData(): { currentMonth: MonthData; nextMonth: MonthData } {
      if (!this.viewingStartYear || this.viewingStartMonth === undefined) {
        return {
          currentMonth: {
            firstDay: null,
            lastDay: null,
            offset: 0,
            days: [],
            monthNumber: 0,
            year: 0,
          },
          nextMonth: {
            firstDay: null,
            lastDay: null,
            offset: 0,
            days: [],
            monthNumber: 0,
            year: 0,
          },
        };
      }

      const currentFirstDay = new Date(
        this.viewingStartYear,
        this.viewingStartMonth,
        1
      );
      const currentLastDay = new Date(
        this.viewingStartYear,
        this.viewingStartMonth + 1,
        0
      );
      const currentDaysInMonth = currentLastDay.getDate();

      const nextYear =
        this.viewingStartMonth === 11
          ? this.viewingStartYear + 1
          : this.viewingStartYear;
      const nextMonth = (this.viewingStartMonth + 1) % 12;

      const nextFirstDay = new Date(nextYear, nextMonth, 1);
      const nextLastDay = new Date(nextYear, nextMonth + 1, 0);
      const nextDaysInMonth = nextLastDay.getDate();

      return {
        currentMonth: {
          firstDay: currentFirstDay,
          lastDay: currentLastDay,
          offset: currentFirstDay.getDay() === 0 ? 7 : currentFirstDay.getDay(),
          days: Array.from({ length: currentDaysInMonth }, (_, d) => d + 1),
          monthNumber: this.viewingStartMonth,
          year: this.viewingStartYear,
        },
        nextMonth: {
          firstDay: nextFirstDay,
          lastDay: nextLastDay,
          offset: nextFirstDay.getDay() === 0 ? 7 : nextFirstDay.getDay(),
          days: Array.from({ length: nextDaysInMonth }, (_, d) => d + 1),
          monthNumber: this.viewingEndMonth,
          year: this.viewingEndYear,
        },
      };
    },
  },
  methods: {
    isValidDate(date: any): date is Date {
      return date instanceof Date && !isNaN(date.getTime());
    },
    increaseMonth(type: "viewingStart" | "viewingEnd") {
      if (type === "viewingStart") {
        if (this.viewingStartMonth < 11) {
          this.viewingStartMonth++;
        } else {
          this.viewingStartMonth = 0;
          this.viewingStartYear++;
        }
      } else {
        if (this.viewingEndMonth < 11) {
          this.viewingEndMonth++;
        } else {
          this.viewingEndMonth = 0;
          this.viewingEndYear++;
        }
      }
    },
    decreaseMonth(type: "viewingStart" | "viewingEnd") {
      if (type === "viewingStart") {
        if (this.viewingStartMonth > 0) {
          this.viewingStartMonth--;
        } else {
          this.viewingStartMonth = 11;
          this.viewingStartYear--;
        }
      } else {
        if (this.viewingEndMonth > 0) {
          this.viewingEndMonth--;
        } else {
          this.viewingEndMonth = 11;
          this.viewingEndYear--;
        }
      }
    },
    decreaseOrDecreaseBothMoths(action: "increase" | "decrease") {
      if (action === "decrease") {
        this.decreaseMonth("viewingStart");
        this.decreaseMonth("viewingEnd");
      } else {
        this.increaseMonth("viewingStart");
        this.increaseMonth("viewingEnd");
      }
    },
    isDateSelected(day: number) {
      if (this.date && !this.selectedDate)
        this.selectedDate = new Date(this.date);
      if (
        this.monthsString[this.viewingStartMonth] !==
          this.monthsString[this.viewingEndMonth] &&
        this.multiDate
      )
        return false;
      return (
        this.selectedDate &&
        this.selectedDate.getDate() === day &&
        this.selectedDate.getMonth() === this.viewingStartMonth &&
        this.selectedDate.getFullYear() === this.viewingStartYear
      );
    },
    selectDate(day: number) {
      const selectedDate = new Date(
        Date.UTC(this.viewingStartYear, this.viewingStartMonth, day)
      );

      if (!this.startDate || selectedDate <= new Date(this.startDate)) {
        this.$emit("onSelectedDateChanged", selectedDate, "startDate");
      } else {
        this.$emit("onSelectedDateChanged", selectedDate, "endDate");
      }

      this.selectedDate = selectedDate;
    },
    selectDateAndEmit(
      day: number,
      month: number,
      year: number,
      type: "startDate" | "endDate"
    ) {
      this.$emit(
        "onSelectedDateChanged",
        new Date(Date.UTC(year, month, day)),
        type
      );
    },
    selectDayMultiDate() {
      if (this.selectDateProp === "start") {
        return (day: number, month: number, year: number) =>
          this.selectDateAndEmit(day, month, year, "startDate");
      }
      if (this.selectDateProp === "end") {
        return (day: number, month: number, year: number) =>
          this.selectDateAndEmit(day, month, year, "endDate");
      }
      return this.selectDate;
    },
  },
});
