






















































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { removeToken } from "@/lib/http/api";
import { mutationsT } from "@/state/mutations";
import QuickActions from "./QuickActions.vue";
import { actionsT } from "@/state/actions";

interface RouteMapping {
  UDV: { from: string[]; to: string };
  KYB: { from: string[]; to: string };
  CDP: { from: string[]; to: string };
  default: { from: string[]; to: string };
}

@Component({
  components: { QuickActions },
})
export default class MainNav extends Vue {
  mobileMenuActive: boolean = false;

  @Watch("$store.state.verificationFlow.app_id")
  onCompanyAppIdChanged() {
    if (this.$store.state.companyAppId === "all") return;
    const flowType = this.$store.state.verificationFlow
      .flow_type as keyof RouteMapping;
    const routeName = this.$route.name;

    const routeMapping = {
      UDV: { from: ["companies", "complex-document-processor"], to: "users" },
      KYB: { from: ["users", "complex-document-processor"], to: "companies" },
      CDP: { from: ["users", "companies"], to: "complex-document-processor" },
      default: {
        from: ["users", "companies", "complex-document-processor"],
        to: "home",
      },
    } as any;

    const { from, to } = routeMapping[flowType] || routeMapping.default;

    if (from.includes(routeName)) {
      this.navigateTo(to);
    }
  }

  navigateTo(routeName: string) {
    if (this.$route.name !== routeName)
      this.$router.push({ name: routeName }).catch((error: Error) => {
        if (
          error.name !== "NavigationDuplicated" &&
          !error.message.includes(
            "Avoided redundant navigation to current location"
          )
        ) {
          console.error(`Error during router push:`, error);
        }
      });
  }

  set selectedCompanyAppId(val) {
    this.updateCompanyAppId(val);
  }

  get selectedCompanyAppId(): string {
    return this.$store.state.companyAppId;
  }

  set selectedCompanyId(val) {
    if (val === "all-companies") {
      this.$store.commit(mutationsT.UPDATE_COMPANY, "all-companies");
      this.$store.commit(mutationsT.UPDATE_COMPANY_APP_ID, "all");
      this.$store.commit(mutationsT.UPDATE_VERIFICATION_FLOW, {});
      if (this.$route.name !== "home") this.$router.push({ name: "home" });
    } else {
      this.getCompanyInfo(val);
      this.$root.$emit("companyIdChanged");
    }
  }

  get selectedCompanyId(): string {
    return (
      (this.$store.state.company && this.$store.state.company.id) ||
      "all-companies"
    );
  }

  get companiesOptions(): Array<any> {
    const defaultValue = {
      value: "all-companies",
      text: this.$t("all_companies"),
    };

    return this.generateOptions(
      this.$store.state.companies,
      "id",
      "name",
      defaultValue
    );
  }

  get companyAppsIdOptions(): Array<any> {
    const companyAppsIdOptions = [];
    if (this.$store.state.companies) {
      for (const company of this.$store.state.companies) {
        for (const CApp of company.company_apps) {
          companyAppsIdOptions.push({
            text: CApp.alias
              ? CApp.alias +
                ` - ${company.name}` +
                ` (${
                  CApp.flow_type === "UDV"
                    ? "KYC"
                    : CApp.flow_type?.toUpperCase()
                })`
              : `${company.name} - ${CApp.app_id.substring(0, 5)}...`,
            value: CApp.app_id,
          });
        }
      }
    }
    if (this.$store.state.userIsStaff) {
      companyAppsIdOptions.push({
        value: "all",
        text: this.$t("all_company_apps"),
      });
    }

    return companyAppsIdOptions;
  }

  get companyAppsFilteredByCompany(): Array<any> {
    const defaultValue = {
      value: "all",
      text: this.$t("all_company_apps"),
    };
    const companyApps =
      this.$store.state.company && this.$store.state.company.company_apps;
    return this.generateOptions(
      companyApps,
      "app_id",
      "alias",
      companyApps?.length > 1 ? defaultValue : null,
      (CApp: any) => {
        return CApp.alias
          ? ` - ${
              this.$store.state.company && this.$store.state.company.name
            } (${
              CApp.flow_type === "UDV" ? "KYC" : CApp.flow_type?.toUpperCase()
            })`
          : "";
      }
    );
  }

  generateOptions(
    items: Array<any>,
    idKey: string,
    textKey: string,
    defaultValue: any,
    additionalTextFn?: Function
  ) {
    const options = [];

    if (items && items.length) {
      for (const item of items) {
        const additionalText = additionalTextFn ? additionalTextFn(item) : "";
        options.push({
          text: item[textKey]
            ? item[textKey] + additionalText
            : `${item[idKey].substring(0, 5)}...${additionalText}`,
          value: item[idKey],
        });
      }
    }

    if (defaultValue) {
      options.unshift(defaultValue);
    }

    return options;
  }

  getCompanyInfo(companyId: string) {
    if (!this.$store.state.companies.length) return;
    const company: any = this.$store.state.companies.find(
      (company: any) => company.id === companyId
    );
    this.$store.commit(mutationsT.UPDATE_COMPANY, company);
    if (company.company_apps.length > 1) this.updateCompanyAppId("all");
    else this.updateCompanyAppId(company.company_apps[0]?.app_id);
  }

  async updateCompanyAppId(companyAppId: string) {
    this.$store.commit(mutationsT.UPDATE_COMPANY_APP_ID, companyAppId);
    if (companyAppId === "all") {
      this.$store.commit(mutationsT.UPDATE_VERIFICATION_FLOW, {});
      this.$router.push({ query: {} });
      if (
        this.$route.name &&
        !["home", "analytics", "settings"].includes(this.$route.name)
      )
        this.$router.push({ name: "home" });
    } else {
      this.$router.push({ query: { verification_flow_id: companyAppId } });
      await this.$store.dispatch(actionsT.FETCH_VERIFICATION_FLOW);
    }
    this.$root.$emit("verificationFlowIdChanged");
  }

  async logout() {
    await removeToken();
    window.sessionStorage.removeItem("silt-vuex");
    setTimeout(() => this.$router.push({ name: "login" }), 100);
    this.$store.commit(mutationsT.RESET_STATE);
  }

  toggleMobileMenu() {
    this.mobileMenuActive = !this.mobileMenuActive;
  }
}
