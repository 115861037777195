












































import { Component, Vue, Watch } from "vue-property-decorator";
import Login from "./Login.vue";
import RecoverPassword from "./RecoverPassword.vue";
import CreatePassword from "./CreatePassword.vue";
import CompanyInfo from "./CompanyInfo.vue";
import { Route } from "vue-router";
import CreateAccount from "./CreateAccount.vue";
import SuccessOverlay from "../../components/SuccessOverlay.vue";
import CompaniesService from "@/api/companies/companiesService";
import { mutationsT } from "@/state/mutations";

@Component({
  components: {
    Login,
    CompanyInfo,
    CreateAccount,
    SuccessOverlay,
    CreatePassword,
    RecoverPassword,
  },
})
export default class Onboarding extends Vue {
  currentView = "";
  success = false;

  @Watch("$route")
  onRouteChange(newRoute: Route) {
    if (newRoute.path == "/login") {
      this.currentView = "login";
    } else if (newRoute.path === "/create-password") {
      this.currentView = "create-password";
    } else if (newRoute.path === "/recover-password") {
      this.currentView = "recover-password";
    } else if (this.currentView !== "welcome") {
      this.currentView = "welcome";
    }
  }

  continueFromCompanyInfo(companyInfo: any) {
    this.currentView = "create-account";
    this.$store.commit("UPDATE_ONBOARDING_INFO", companyInfo);
  }

  async continueFromCreateAccount(accountInfo: any) {
    this.$store.commit("UPDATE_ONBOARDING_INFO", accountInfo);
    try {
      await this.createCompany();
      this.success = true;
    } catch {}
  }

  async createCompany() {
    try {
      if (
        !(
          this.$store.state.onboardingInfo &&
          this.$store.state.onboardingInfo.companyName
        )
      )
        throw "A name for the company must be provided";
      const companyData = {
        name: this.$store.state.onboardingInfo.companyName,
        legal_name: this.$store.state.onboardingInfo.companyName,
        owners: [
          { email: this.$store.state.onboardingInfo.email.toLowerCase() },
        ],
        meta: {
          pipedrive_deal_id:
            this.$store.state.companyInfo &&
            this.$store.state.companyInfo.dealId,
        },
      };
      const companyCreated = await CompaniesService.postCompany(companyData);
      if (companyCreated.data && companyCreated.data.company_apps[0].app_id)
        this.$store.commit(
          mutationsT.UPDATE_COMPANY_APP_ID,
          companyCreated.data.company_apps[0].app_id
        );
    } catch (error: any) {
      throw { message: "Error creating the company", error };
    }
  }

  created() {
    this.onRouteChange(this.$router.currentRoute);
  }
}
