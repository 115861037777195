import Vue from "vue";

import VTable from "./tables/VTable.vue";
Vue.component("v-table", VTable);

import VModal from "./modals/VModal.vue";
Vue.component("v-modal", VModal);

import VTab from "./tabs/VTab.vue";
Vue.component("v-tab", VTab);

import VTabRadioButton from "./tabs/VTabRadioButton.vue";
Vue.component("VTabRadioButton", VTabRadioButton);

import VProgressTab from "./tabs/VProgressTab.vue";
Vue.component("VProgressTab", VProgressTab);

import VToast from "./toast/VToast.vue";
Vue.component("v-toast", VToast);

import VSwitch from "./switch/VSwitch.vue";
Vue.component("VSwitch", VSwitch);

import VDragAndDropDocument from "./documents/VDragAndDropDocument.vue";
Vue.component("VDragAndDropDocument", VDragAndDropDocument);

// Buttons
import VButton from "./buttons/VButton.vue";
Vue.component("v-button", VButton);

// Fields
import VCheckbox from "./inputs/VCheckbox.vue";
Vue.component("v-checkbox", VCheckbox);

import VExpandInput from "./inputs/VExpandInput.vue";
Vue.component("v-expand-input", VExpandInput);

import VDatePicker from "./inputs/VDatePicker.vue";
Vue.component("v-date-picker", VDatePicker);

import VDatePickerStartEnd from "./inputs/VDatePickerStartEnd.vue";
Vue.component("VDatePickerStartEnd", VDatePickerStartEnd);

import VInput from "./inputs/VInput.vue";
Vue.component("v-input", VInput);

import VInputFile from "./inputs/VInputFile.vue";
Vue.component("VInputFile", VInputFile);

import VInputPassword from "./inputs/VInputPassword.vue";
Vue.component("v-input-password", VInputPassword);

import VInputIban from "./inputs/VInputIban.vue";
Vue.component("v-input-iban", VInputIban);

import VInputMoney from "./inputs/VInputMoney.vue";
Vue.component("v-input-money", VInputMoney);

import VMultiselectInput from "./inputs/VMultiselectInput.vue";
Vue.component("VMultiselectInput", VMultiselectInput);

import VFilters from "./inputs/VFilters.vue";
Vue.component("VFilters", VFilters);

import VSelect from "./inputs/VSelect.vue";
Vue.component("v-select", VSelect);

import VSelectSearch from "./inputs/VSelectSearch.vue";
Vue.component("v-select-search", VSelectSearch);

import VTextArea from "./inputs/VTextArea.vue";
Vue.component("v-text-area", VTextArea);

import VTextField from "./inputs/VTextField.vue";
Vue.component("v-text-field", VTextField);

import VImage from "./inputs/VImage.vue";
Vue.component("v-image", VImage);

import VPlaceholder from "./placeholder/VPlaceholder.vue";
Vue.component("VPlaceholder", VPlaceholder);

import VInputNumber from "./inputs/VInputNumber.vue";
Vue.component("VInputNumber", VInputNumber);

import VFlag from "./utils/VFlag.vue";
Vue.component("VFlag", VFlag);