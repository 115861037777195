














import { Component, Vue } from "vue-property-decorator";
import emailApi from "@/api/email/emailApi";

@Component({})
export default class Contact extends Vue {
  form: any = {
    values: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      message: "",
    },
    dirty: {
      first_name: false,
      last_name: false,
      email: false,
      phone: false,
      message: false,
    },
  };
  responseStatus: string = "";
  isLoading: boolean = false;

  get validEmail() {
    const re =
      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(this.form.values.email);
  }

  async sendEmail() {
    this.form.dirty = {
      first_name: true,
      last_name: true,
      email: true,
      phone: true,
      message: true,
    };
    if (this.validEmail && this.isFormValid && !this.isLoading) {
      this.isLoading = true;
      try {
        const res = await emailApi.sendEmail(this.form.values);
        this.responseStatus = "success";
        this.resetForm();
      } catch (error: any) {
        this.responseStatus = "error";
      }
      this.isLoading = false;
    }
    return false;
  }

  get isFormValid() {
    for (const key of Object.keys(this.form.values)) {
      if (!this.form.values[key] && key !== "phone") return false;
    }
    return this.validEmail;
  }

  resetForm() {
    this.form.values = {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      message: "",
    };
    this.form.dirty = {
      first_name: false,
      last_name: false,
      email: false,
      phone: false,
      message: false,
    };
  }
}
