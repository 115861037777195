
























import Vue from "vue";

export default Vue.extend({
  name: "VDatePickerCalendarGrid",
  props: {
    checkIfSelected: { required: true, type: Function },
    selectDate: { required: true, type: Function },
    monthData: { required: true, type: Object },
    startDate: { required: false, type: Date },
    endDate: { required: false, type: Date },
  },
  data() {
    return {
      daysString: ["M", "T", "W", "T", "F", "S", "S"],
    };
  },
  methods: {
    checkIfMiddle(day: number) {
      if (!this.startDate || !this.endDate) return;
      const dateToCheck = new Date(
        this.monthData.firstDay.getFullYear(),
        this.monthData.firstDay.getMonth(),
        day
      );
      return (
        dateToCheck > new Date(this.startDate) &&
        dateToCheck < new Date(this.endDate)
      );
    },
    isStartOrEndDate(date: Date, day: number) {
      return (
        date &&
        date.getDate() === day &&
        date.getMonth() === this.monthData.firstDay.getMonth() &&
        date.getFullYear() === this.monthData.firstDay.getFullYear()
      );
    },
  },
});
