import { State, initialState } from "./store";
import { setCompanyAppHeader } from "@/lib/http/api";
export const mutationsT = {
  UPDATE_COMPANY: "UPDATE_COMPANY",
  UPDATE_COMPANY_APP_ID: "UPDATE_COMPANY_APP_ID",
  UPDATE_COMPANIES: "UPDATE_COMPANIES",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_ONBOARDING_INFO: "UPDATE_ONBOARDING_INFO",
  UPDATE_VERIFICATION_FLOW: "UPDATE_VERIFICATION_FLOW",
  UPDATE_LOADING: "UPDATE_LOADING",
  RESET_STATE: "RESET_STATE"
};

export const mutations = {
  UPDATE_VERIFICATION_FLOW(state: State, payload: any) {
    state.verificationFlow = payload
    if (payload === "all-companies") return
    if (!state.user) return
    if (payload.admins && payload.admins.length) {
      for (const admin of payload.admins) {
        const userId = state.user.id; 
        if (admin.id === userId) state.userRole = admin.role
      }
    }
  },
  async UPDATE_COMPANY_APP_ID(state: State, payload: any) {
    state.companyAppId = payload;
    setCompanyAppHeader();
    let newCompany = null;
    for (let comp of state.companies) {
      for (let comp_capp of comp.company_apps) {
        if (comp_capp.app_id === payload) {
          newCompany = comp;
          break;
        }
      }
    }
    if (newCompany) state.company = newCompany;
    if (!state.company && state.companies.length > 1) state.company = "all-companies"
  },
  UPDATE_COMPANIES(state: State, payload: any) {
    state.companies = payload;
    let newCompany = null;
    if (state.companyAppId) {
      stateCompany: for (let comp of state.companies) {
        for (let comp_capp of comp.company_apps) {
          if (comp_capp.app_id === state.companyAppId) {
            newCompany = comp;
            break stateCompany;
          }
        }
      }
      if (newCompany) {
        state.company = newCompany;
      }
    }
  },
  UPDATE_COMPANY(state: State, company: any) {
    state.company = company;    
  },

  UPDATE_USER(state: State, payload: any) {
    state.user = payload;
    state.userIsStaff = payload.company_app_admin_role === "STAFF";
    state.userRole = payload.company_app_admin_role
  },
  UPDATE_ONBOARDING_INFO(state: State, payload: any) {
    state.onboardingInfo = { ...state.onboardingInfo, ...payload };
  },
  UPDATE_LOADING(state: State, payload: Boolean) {
    state.loading = payload
  },
  RESET_STATE(state: State) {
    Object.assign(state, initialState);
  }
};
